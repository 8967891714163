import axios from "@/axios"

const url = "/api/brain/warehouse/sub-category"

class SubCategoriesService {
	async getSubCategories(params) {
		try {
			const data = await axios.get(`${url}/`, { params })
			return data.data
		} catch (error) {
			throw error
		}
	}

	async createSubCategories(datas) {
		try {
			const data = await axios.post(`${url}/`, datas)
			return data.data
		} catch (error) {
			throw error
		}
	}

	async deleteSubCategories(id) {
		try {
			const data = await axios.delete(`${url}/${id}`)
			return data.data
		} catch (error) {
			throw error
		}
	}
	async updateSubCategories(id, datas) {
		try {
			const data = await axios.put(`${url}/${id}`, datas)
			return data.data
		} catch (error) {
			throw error
		}
	}
}

export default new SubCategoriesService()
