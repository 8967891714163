<template>
	<b-modal
		ref="modal-create-subcategory"
		:title="getTitle"
		@hidden="$emit('close')"
		size="md"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div>
			<ValidationObserver ref="formSubcategory">
				<ValidationProvider v-slot="{ errors }" rules="required" v-if="!info">
					<b-form-group :state="errors[0] ? false : null">
						<label for="category_id">Categoria</label>
						<v-select
							input-id="category_id"
							:clearable="false"
							v-model="category_id"
							label="name"
							:options="categories"
							:reduce="(cc) => cc.id"
							placeholder="Seleccione una categoria"
						/>
					</b-form-group>
				</ValidationProvider>

				<ValidationProvider v-slot="{ errors }" rules="required">
					<b-form-group :state="errors[0] ? false : null">
						<label for="name">Nombre</label>
						<b-form-input
							id="name"
							:state="errors[0] ? false : null"
							type="text"
							v-model="subcategory_name"
							placeholder="Ingrese nombre de la sub-categoria"
						/>
					</b-form-group>
				</ValidationProvider>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<b-button variant="success" @click="!info ? create() : update()">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import CategoriesService from "@/views/brain/administrative/views/categories/services/categories.service.js"
import SubCategoriesService from "@/views/brain/administrative/views/sub-categories/services/sub-categories.service.js"

export default {
	props: {
		info: {},
	},
	mixins: [modalMixin],
	data() {
		return {
			categories: [],
			category_id: null,
			subcategory_name: "",
		}
	},
	async mounted() {
		this.toggleModal("modal-create-subcategory")
	},
	async created() {
		await this.getCategories()
	},
	computed: {
		getTitle() {
			return this.info ? `Editar sub-categoria (${this.info.name})` : `Crear sub-categoria`
		},
	},
	methods: {
		closeModal() {
			this.$emit("close")
		},
		async getCategories() {
			this.isPreloading()

			try {
				const { data } = await CategoriesService.getCategoriesForSelect()
				this.categories = data.data
			} catch (error) {
				throw error
			} finally {
				this.isPreloading(false)
			}
		},
		async create() {
			if (!(await this.$refs.formSubcategory.validate())) return

			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isPreloading()

			try {
				const res = await SubCategoriesService.createSubCategories({
					category_id: this.category_id,
					name: this.subcategory_name,
				})
				this.showSuccessToast(res.message)
				this.$emit("close")
				this.$emit("refresh")
			} catch (error) {
				throw error
			} finally {
				this.isPreloading(false)
			}
		},
		async update() {
			if (!(await this.$refs.formSubcategory.validate())) return

			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isPreloading()

			try {
				const res = await SubCategoriesService.updateSubCategories(this.info.id, {
					name: this.subcategory_name,
				})
				this.showSuccessToast(res.message)
				// this.$emit("close")
				this.$emit("refresh")
			} catch (error) {
				throw error
			} finally {
				this.isPreloading(false)
			}
		},
	},
}
</script>
